<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-30 14:24:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-31 16:53:02
 * @FilePath: /bunnyWebsite/src/pages/index/SectionChoose.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="section-item section-economics">
    <div class="normal-layout section-content">
      <div class="subtitle">
        {{ localeData["origin.title"] }}
      </div>
    </div>
  </section>
  <section class="section-item section-origin">
    <div class="normal-layout section-content">
      <div class="rowItem">
        <div class="content-right">
          <img class="image" src="../../assets/bunny/6.png" />
          <div class="subtitle">
            {{ localeData["origin.subtitle"] }}
          </div>
        </div>
        <div class="content-left">
          <div class="imageView">
            <img class="image" src="../../assets/bunny/5.png" />
            <img class="image2" src="../../assets/bunny/7.png" />
            <div class="leftTitlte">{{ localeData["origin.chain"] }}</div>
            <div class="leftTitlte1">{{ localeData["origin.game"] }}</div>
            <div class="rightTitlte">{{ localeData["origin.finance"] }}</div>
            <div class="rightTitlte1">{{ localeData["origin.art"] }}</div>
          </div>
        </div>
      </div>
      <div class="rowItem">
        <div class="title">{{ localeData["origin.content"] }}</div>
      </div>
    </div>
  </section>
</template>

<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>
<script>
export default {
  name: "CommonVideo",
  data() {
    return {
      isPlaying: false,
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "./index.less";

@media screen and (min-width: 960px) {
  .section-origin {
    .section-content {
      margin: auto;
      padding-top: 30px;
      margin-bottom: 0px;
      display: flex;
      flex-flow: column;

      .rowItem {
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        flex-flow: row;
        .content-left {
          margin: auto;
          display: flex;
          flex-flow: row;
          width: 50%;
          justify-content: center;
          .imageView {
            position: relative;
            .image {
              top: 0;
              left: 0;
            }
            .image2 {
              position: absolute;
              top: 42%;
              left: 32%;
            }
            .leftTitlte {
              margin: auto;
              font-size: 20px;
              text-align: left;
              width: 100px;
              margin-top: -82%;
              position: absolute;
              color: #0894a9;
            }
            .leftTitlte1 {
              margin: auto;
              font-size: 20px;
              text-align: right;
              width: 100px;
              margin-top: -82%;
              margin-left: 81%;
              position: absolute;
              color: #9140c3;
            }
            .rightTitlte {
              margin: auto;
              position: absolute;
              font-size: 20px;
              text-align: left;
              width: 100px;
              color: #54b247;
            }
            .rightTitlte1 {
              margin: auto;
              position: absolute;
              font-size: 20px;
              text-align: right;
              margin-left: 81%;
              width: 100px;
              color: #4141c3;
            }
          }
        }
        .content-right {
          margin: auto;
          margin-left: 20px;
          margin-right: 20px;
          width: 50%;
          text-align: center;
          .title {
            margin: auto;
            font-size: 40px;
            text-align: left;
            width: 100%;
          }
          .subtitle {
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: center;
            font-size: 20px;
            white-space: pre-line;
            color: #ffffff;
          }
       
        }
        .title {
          margin: auto;
          margin-top: 20px;
          margin-bottom: 20px;
          text-align: center;
          font-size: 20px;
          white-space: pre-line;
          color: #ffffff;
        }
      }
    }
  }
  .section-economics {
    width: 100%;
    height: 120px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 25px;
      margin-bottom: 25px;
      font-size: 40px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 960px) {
  .section-origin {
    .section-content {
      margin: auto;
      padding-top: 30px;
      margin-bottom: 0px;
      display: flex;
      flex-flow: column;

      .rowItem {
        margin: auto;
        margin-bottom: 30px;
        display: flex;
        flex-flow: column;
        .content-left {
          margin: auto;
          display: flex;
          flex-flow: row;
          width: 100%;
          justify-content: center;
          .imageView {
            position: relative;
            .image {
              top: 0;
              left: 0;
            }
            .image2 {
              position: absolute;
              top: 41%;
              left: 30%;
              width: 40%;
            }
            .leftTitlte {
              margin: auto;
              font-size: 14px;
              text-align: left;
              width: 100px;
              margin-top: -82%;
              position: absolute;
              color: #0894a9;
            }
            .leftTitlte1 {
              margin: auto;
              font-size: 16px;
              text-align: right;
              width: 100px;
              margin-top: -82%;
              margin-left: 71%;
              position: absolute;
              color: #9140c3;
            }
            .rightTitlte {
              margin: auto;
              position: absolute;
              font-size: 14px;
              text-align: left;
              width: 100px;
              color: #54b247;
            }
            .rightTitlte1 {
              margin: auto;
              position: absolute;
              font-size: 14px;
              text-align: right;
              margin-left: 71%;
              width: 100px;
              color: #4141c3;
            }
          }
        }
        .content-right {
          margin: auto;
          width: 100%;
          text-align: center;
          .title {
            margin: auto;
            font-size: 14px;
            text-align: left;
            width: 100%;
          }
          .subtitle {
            margin: auto;
            margin-top: 10px;
            margin-bottom: 30px;
            text-align: left;
            font-size: 16px;
            color: #ffffff;
          }
          .image{
            width: 40%;
          }
       
        }
        .title {
          margin: auto;
          margin-top: 20px;
          text-align: left;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }
  .section-economics {
    width: 100%;
    height: 60px;
    background-size: contain;
    background-image: linear-gradient(#303030, #1d1d1d);
    .subtitle {
      margin: auto;
      padding-top: 15px;
      margin-bottom: 15px;
      font-size: 20px;
      text-align: center;
    }
  }
}
</style>