<template>
  <nav class="navbar">
    <section class="navbar__inner frbc">
      <section class="navbar__left frsc">
        <a href class="logo">
          <h1 class="logo-text"></h1>
        </a>
        <ul class="menus frsc">
          <li class="menu-item">
            <router-link to="/" class="frsc">
              {{ localeData["navbar.home"] }}</router-link
            >
          </li>
          <li class="menu-item">
            <a class="frsc" href="https://dapp.zeptosfintech.io/buy">
              <span>{{ localeData["navbar.market"] }}</span>
              <!-- <img src="../../assets/ball.png" class="menu-arrow menu-arrow__new" alt /> -->
            </a>
          </li>
          <li class="menu-item">
            <a
              class="frsc"
              href="https://game.zeptosfintech.io/"
              target="_blank"
            >
              <span>{{ localeData["navbar.game"] }}</span>
              <img
                src="../../assets/bunny/hot.png"
                class="menu-arrow menu-arrow__hot"
                alt
              />
            </a>
          </li>
          <li class="menu-item" @click="clickButton(1)">
            <router-link to="" class="frsc">
              <span>{{ localeData["navbar.rabbit"] }}</span>
            </router-link>
          </li>
          <li class="menu-item" @click="clickButton(2)">
            <router-link to="" class="frsc">
              <span>{{ localeData["navbar.bunny"] }}</span>
            </router-link>
          </li>
          <li class="menu-item" @click="clickButton(3)">
            <router-link to="" class="frsc">
              <span>{{ localeData["navbar.team"] }}</span>
            </router-link>
          </li>
          <li class="menu-item" @click="clickButton(4)">
            <router-link to="" class="frsc">
              <span>{{ localeData["navbar.roadMap"] }}</span>
            </router-link>
          </li>
          <li class="menu-item">
            <a href="pdf/docs_en.pdf" target="_blank"><span>{{ localeData["navbar.docs"] }}</span></a>
          </li>
          <li class="menu-item">
            <router-link to="/gather" class="frsc">
              <span>{{ localeData["Contact.title"] }}</span>
            </router-link>
          </li>
        </ul>
      </section>
      <section class="navbar__right frsc">
        <div class="entrys">
          <Collaspe></Collaspe>
          <LngSelect></LngSelect>
        </div>
      </section>
    </section>
  </nav>
</template>

<script setup>
import Collaspe from "@/components/Collaspe";

import LngSelect from "@/components/LngSelect";

import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>

<script>
export default {
  name: "navbar",
  data() {
    return {
      page: 1,
    };
  },
  mounted() {},
  methods: {
    clickButton(page) {
      switch (page) {
        case 2:
          document
            .getElementById("section-game")
            .scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case 3:
          document
            .getElementById("section_friends")
            .scrollIntoView({ behavior: "smooth", block: "start" });
          break;
        case 4:
          document
            .getElementById("section-team")
            .scrollIntoView({ behavior: "smooth", block: "start" });
          break;
          case 5:
          // document
          //   .getElementById("section_about")
          //   .scrollIntoView({ behavior: "smooth", block: "start" });
          break;


        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  height: 100px;
  width: 100%;

  &__inner {
    position: fixed;
    height: 100px;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
    z-index: 100;
    background: #191919;
  }
}
.logo {
  width: 136px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0;
  background-image: url(../../assets/bunny/7.png);
}
.logo-text {
  text-indent: -999em;
}
.menus {
  margin-left: 60px;
  a {
    font-size: 16px;
    font-weight: 600;
    margin-left: 40px;
    white-space: nowrap;
    transition: all 0.5s;
  }
  a:hover {
    color: #c86432;
  }
  .menu-item {
    color: white;
  }
}
.menu-arrow {
  width: 18px;
  height: 18px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  margin-left: 8px;
  &.menu-arrow__new {
    width: 40px;
  }
}
.entrys {
  display: flex;
  align-items: center;
}
.entrys .entry-item {
  margin-left: 25px;
  color: white;
}

.entry-item-wallet {
  white-space: nowrap;
}

.no-open {
  color: #888;
}
.no-open:hover {
  color: #888;
}

@media screen and (min-width: 960px) and (max-width: 1280px) {
  .navbar__inner {
    padding: 0 20px;
  }
  .menus {
    margin-left: 10px;
    a {
      margin-left: 20px;
      font-size: 15px;
    }
    .menu-item {
      color: white;
    }
  }
}
@media screen and (max-width: 960px) {
  .navbar .navbar__inner,
  .navbar {
    height: 72px;
  }
  .menus {
    display: none;
    .menu-item {
      color: white;
    }
  }
  .entry-item-wallet {
    display: none;
  }
  .navbar__inner {
    padding: 0 10px 0 18px;
    box-sizing: border-box;
  }
  .logo {
    width: 100px;
    height: 46px;
  }
  .entrys .entry-item {
    margin-left: 14px;
  }
  .entrys .entry-item-menu {
    display: flex;
  }
}
</style>