/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-30 13:01:44
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-18 11:39:50
 * @FilePath: /bunnyWebsite/src/locale/en.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    'navbar.home': 'Home',
    'navbar.market': 'Market',
    'navbar.game': 'Games',
    'navbar.rabbit': 'Double Rabbit',
    'navbar.bunny': 'Double Bunny',
    'navbar.team': 'Roadmap',
    'navbar.roadMap': 'Team',
    'navbar.docs': 'Docs',
    'navbar.about': 'About',
    'home.title': 'ZEPTOS provides NFT-based game scenarios and data marketing',

    "choose.title": "Choose Double Rabbit NFT",
    "choose.uniqueId": "Unique ID:",
    "choose.uniqueIdTtitle": " Unique Identity.",
    "choose.collection": "Collection:",
    "choose.collectionTitle": " Collection, Finance, Increase in value, Dividends.",
    "choose.Pledge": "Staking:",
    "choose.PledgeTitle": " Pledge, Guaranteed 3 Times Return.",
    "choose.mint": "Mint:",
    "choose.mintTitle": " Mint and Combine to Increase Value ",
    "choose.buy": "Buy Now",

    "game.title": "WEB3.0 GAME-FI Game Station",
    "game.subtitle": "Double Bunny",
    "game.content": "A blockchain game for all ages!\nEnter Play through blockchain wallets.\nThose Can even play without NFTs!",
    "game.content1": "Get puzzle pieces every day, 5 pieces can be combined into a Rocket! \n Rocket can increase computing power by 5%-100%, accelerating the release of rights.",
    "game.paly": "Play",


    "origin.title": "ZEPTOS Origin",
    "origin.subtitle": " Zeptos' mission is to provide a trustworthy\nWeb 3.0 platform for everyone and offer a fair and transparent market.",
    "origin.content": "Zeptos team consists of experts in blockchain, gaming, finance, art and Web 3.0 technology, we are aiming to build a unique, highly growth-oriented metaverse ecosystem.",
    "origin.chain": "Blockchain",
    "origin.game": "Game",
    "origin.finance": "Finance",
    "origin.art": "Art",



    "stake.title": "NFT Mining",
    "stake.subtitle": "ZEP ecosystem tokens can be collected by consuming HP, empowering the ecosystem",
    "stake.description0": "When HP is full, burning NFTs can convert them into ZEP tokens",
    "stake.description1": "Minting ZEP tokens will consume HP",
    "stake.description2": "ZEP tokens are continuously minted based on minting power",
    "stake.description3": "When HP is exhausted, ZEP token minting stops",


    "pledge.title": "NFT Staking",
    "pledge.subtitle": "NFTs and ZEP tokens can be permanently retained through annualized returns, and ultimately gain wealth",
    "pledge.content": "NFT and ZEP can be staked to obtain a annualized returns growth rate of 7%-42%",


    "roadmap.title": "Roadmap",
    "roadmap.r1": "",
    "roadmap.r1_info": ["", "", "", ""],
    "roadmap.r1_points": ["Team Formation", "Development of Concepts and Ideas", "Strategy Partnership with Double Rabbit NFT", "Launch of Double Rabbit NFT", "NFT Game-Fi Goes Online", "Double Rabbit NFT X Qatar World Cup 2022", "Obtained US VC Series A funding of $6 million"],
    "roadmap.r2": "",
    "roadmap.r2_info": ["", "", "", ""],
    "roadmap.r2_points": ["CEX/DEX Listing", "BSC NFT Staking", "NFT will be listed on the main market", "Progress of minted NFT", "Launch 3D NFT", "Launch 3D Game-Fi", "APR Staking", "Launch NFT Toys", "Launch Social-Fi"],
    "roadmap.r3": "",
    "roadmap.r3_info": ["", "", "", ""],
    "roadmap.r3_points": ["Target daily active users 50 thousand", "Complete the Metaverse of the Education Institute", "Listed on the Hong Kong Stock", "AI NFT projects", "Hong Kong Exchange Market NFT License", "NFT Product Verification", "NFT Cross-chain Technology"],
    "roadmap.r4": "",
    "roadmap.r4_info": ["", "", "", ""],
    "roadmap.r4_points": ["Target daily active users 200 thousand", "Singapore NFT market license", "Zeptos Metaverse world map", "Zeptos X real estate development", "Realize jobs in the Metaverse"],





    "team.title": "Team",
    "team.ceo_title": "CEO (Founder)",
    "team.ceo_name": "Chris Ho",
    "team.ceo_introduce": "Ho worked in well-known domestic listed companies such as Tencent, Ali, Onchain, etc., and participated in the formulation of authoritative standards in China, including the Blockchain Reference Architecture led by the Electronic Technology Standard Research Institute of the Ministry of Industry and Information Technology of China, and the China Academy of Information and Communications Technology Trusted Blockchain Supply Chain Collaborative Application White Paper etc.",
    "team.coo_title": "COO (Founder)",
    "team.coo_name": "James Chen",
    "team.coo_introduce": "Chen worked in well-known domestic listed companies such as Tencent, Giant, Onchain, etc., and has long experience in R&D. Chen has participated in AR/VR, Instant-ngp 3D reconstruction technology, and game-based LBS map services. technology development.",
    "team.cbo_title": "Marketing Director",
    "team.cbo_name": "Eric Zhao",
    "team.cbo_introduce": "Zhao worked in many companies such as Alibaba, Netease, and Snail, a industry veteran. Zhao has participated in multiple rounds of A-round investment projects such as Fosun and Hongshan.",
    "team.cto_title": "Technical Director",
    "team.cto_name": "Carl",
    "team.cto_introduce": "Carl worked in Baidu, Ant Group and other blockchain-related companies. Carl is a experienced of web3.0 technology research and development. Carl has led the team to develop more than 50 blocks. chain application.",

    "partner.title": "Partners",


    "docs.next": "Next",
    "docs.previous": "Previous",

    "about.title": "About",
    "Contact.title": "Contact Us",

    "navigate.title": "Navigate",
    "navigate.subtitle0": "1",
    "navigate.subtitle0": "2",
    "navigate.subtitle0": "3",
    "community":"community",

    "brandGuide.title": "Brand Guide",
    "contact_content.title":"LET'S CHAT",
    "contact_content.subtitle":"Where you have a question,\n want to submit a business plan or simply\nwant to connect.\nFeel free to send me a message\nin the contact form",
    "contact.title":"CONTACT",
    "contact.required":"Required",
    "submit.title":"Submit",
    "contactname":"Name *",
    "contactemail":"Email *",
    "contactcompany":"Reason",
    "contactmessage":"Message",
    "success.title":"The information has been uploaded successfully.",
    "fail.title":"Failed to upload information, please try again!",
    "warning.title":"Please complete the form information",

    "news.title":"NEWS",
    "news.more":"More",

}

