<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-08-10 16:28:04
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-10 18:20:54
 * @FilePath: /bunnyWebsite/src/pages/index/SectionAbout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="section-item section-about">
    <div class="normal-layout section-content">
      <div class="contentView" id="section_about">
        <div class="leftView">
          <div class="left_icon"></div>
          <div class="left_title">{{ localeData["home.title"] }}</div>
        </div>
        <div class="middleView">
          <div class="middle_about">
            <div class="middle_title">{{ localeData["about.title"] }}</div>
            <div class="middle_subtitle">
              {{ localeData["brandGuide.title"] }}
            </div>
            <div class="middle_subtitle">
              <router-link to="/gather" class="frsc">
                <span>{{ localeData["Contact.title"] }}</span>
              </router-link>
            </div>
          </div>
          <div class="middle_about" style="width: 75%">
            <div class="middle_title">{{ localeData["navigate.title"] }}</div>
            <div class="middle_subtitle">
              <p class="child_Item" @click="btnClick(0)">
                {{ localeData["navbar.game"] }}
              </p>
              <p class="child_Item" @click="btnClick(1)">
                {{ localeData["navbar.rabbit"] }}
              </p>
              <p class="child_Item" @click="btnClick(2)">
                {{ localeData["navbar.market"] }}
              </p>
            </div>
          </div>
        </div>
        <div class="rightView">
          <div class="community">{{ localeData["community"] }}</div>
          <div class="socials">
            <div class="normal-layout">
              <div class="social-list frsc communIcon">
                <a
                  class="frcc"
                  href="https://twitter.com/zeptosfintec"
                  target="_blank"
                >
                  <img src="../../assets/image_1.png" />
                </a>
                <a class="frcc" href="" target="_blank">
                  <img src="../../assets/image_2.png" />
                </a>
                <a class="frcc" href="" target="_blank">
                  <img src="../../assets/image_4.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>

<script>
export default {
  name: "about",
  data() {
    return {
      isPlaying: false,
    };
  },

  mounted() {},

  methods: {
    btnClick(type) {
      switch (type) {
        case 0:
          window.open("https://game.zeptosfintech.io/");
          break;
        case 1:
          window.open("https://dapp.zeptosfintech.io/");
          break;
        case 2:
          window.open("https://mall.zeptosfintech.io/");
          break;
        case 3:
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";

@media screen and (min-width: 960px) {
  .section-about {
    .economics {
      padding-top: 60px;
    }

    .contentView {
      margin: auto;
      width: 100%;
      display: flex;
      .leftView {
        width: 30%;
        .left_icon {
          margin: auto;
          margin-top: 20px;
          width: 136px;
          height: 60px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 0;
          background-image: url(../../assets/bunny/7.png);
        }
        .left_title {
          padding: 20px;
          text-align: center;
          font-size: 14px;
          color: white;
        }
      }
      .middleView {
        width: 50%;
        display: flex;
        .middle_about {
          margin: auto;
          margin-top: 20px;
          width: 25%;
          text-align: left;
          color: white;
          .middle_title {
            font-size: 16px;
            font-weight: 700;
          }
          .middle_subtitle {
            font-size: 14px;
            margin-top: 20px;
            display: flex;
            .child_Item {
              margin: auto;
              margin-left: 0px;
              font-size: 14px;
              color: white;
              text-align: left;
            }
          }
        }
      }
      .rightView {
        width: 20%;
        margin: auto;
        margin-top: 20px;
        .community {
          text-align: left;
          color: white;
          font-size: 16px;
          font-weight: 700;
        }
        .socials {
          margin-top: 14px;
          position: relative;
        }
        
        .social-list a {
          margin-left: 15px;
          width: 30px;
          height: 30px;
          border-radius: 8px;
          box-sizing: border-box;
          transition: border-color 0.2s ease;
          &:first-child {
            margin-left: 0;
          }
          &:hover {
            border-color: #c86432;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .section-about {
    .economics {
      padding-top: 60px;
    }
    .title {
      text-align: center;
      padding-top: 60px;
    }
  }
  .contentView {
    margin: auto;
    width: 100%;
    display: flex;
    .leftView {
      width: 30%;
      .left_icon {
        margin: auto;
        margin-top: 20px;
        width: 54px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0;
        background-image: url(../../assets/bunny/7.png);
      }
      .left_title {
        padding: 20px;
        text-align: center;
        font-size: 12px;
        color: white;
      }
    }
    .middleView {
      width: 60%;
      display: flex;
      .middle_about {
        margin: auto;
        margin-top: 20px;
        width: 30%;
        text-align: left;
        color: white;
        .middle_title {
          font-size: 14px;
          font-weight: 700;
        }
        .middle_subtitle {
          font-size: 12px;
          margin-top: 20px;
          display: flex;
          .child_Item {
            margin: auto;
            margin-left: 0px;
            font-size: 12px;
            color: white;
            text-align: left;
          }
        }
      }
    }
    .rightView {
      width: 10%;
      margin: auto;
      margin-top: 20px;
      .community {
        text-align: left;
        color: white;
        font-size: 16px;
        font-weight: 700;
      }
      .socials {
          margin-top: 14px;
          position: relative;
        }
        .social-list a {
          margin-left: 0px;
          margin-bottom:4px;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          box-sizing: border-box;
          transition: border-color 0.2s ease;
          &:first-child {
            margin-left: 0;
          }
          &:hover {
            border-color: #c86432;
          }

        }
        .communIcon{
          display: grid;
          margin: auto;
          margin-left: -10px;
        }
    }
  }
}
</style>