<template>
  <section class="section-item section-home">
    <div class="normal-layout section-content frsc">
      <div class="content-left">
        <div class="title">
          {{ localeData["home.title"] }}
          <img src="../../assets/bunny/1.png" />
        </div>
      </div>
      <div class="content-right" id="certify">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="new-banner swiper-slide" v-for="(item, index) in datas" :key="index">
              <img v-if="videoType(item.adUrl) == 'img'" :src="item.adUrl" />

              <video v-else class="itemVideo" ref="video0" playsinline loop="loop" poster="" controls="controls"
                :id="item.id">
                <source src="../../assets/banner/bannervideo.mp4" type="video/mp4" />
              </video>
              <!-- @click="openVideo(item.adUrl, item.id)" -->
              <div v-if="videoType(item.adUrl) == 'img'&&!item.type" class="textView">
                <p>{{ item.title }}</p>
              </div>
              <div v-if="item.type" class="text_teview">
                <div class="_title">{{ item.title }}</div>
                <div class="content">
                  <pre>{{ item.content }}</pre>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>

        <div>
          <div style="height: 20px;">
            <span style="position: relative; float: right;cursor: pointer;margin-right: 10px;" @click="allNews()">{{ localeData["news.more"] }}</span>
          </div>
          <div class="news-list">
            <div class="new-item" v-for="(item,index) in news.slice(news.length - 3,news.length).reverse()" style="margin-top: 5px;" @click="clickNewItem(item)">
              <a>{{ item.title}}</a>
              <a style="float: right;font-size: 11px;position: relative;top: 8px;">{{ item.date }}</a>
              <div class="line"></div>
            </div>
          </div>
      
        </div>
   
      </div>
    </div>
    <div class="socials">
      <div class="normal-layout">
        <div class="social-list frsc">
          <a class="frcc" href="https://twitter.com/zeptosfintec" target="_blank">
            <img src="../../assets/image_1.png" />
          </a>
          <a class="frcc" href="" target="_blank">
            <img src="../../assets/image_2.png" />
          </a>
          <a class="frcc" href="" target="_blank">
            <img src="../../assets/image_4.png" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import useLocale from "@/hooks/useLocale";
const localeData = useLocale();
</script>

<script>
import Swiper from "../../locale/swiper-bundle.min.js";

export default {
  data() {
    return {
      isShow: false,
      videoid: "",
      imgShow: true,
      datas: [
        {
          adUrl: require("../../assets/banner/banner3.jpeg"),
          id: 1,
          title: "Master Huang Opening ceremony",
          date: 0,
          content: "",
          type:0,
        },
        {
          adUrl: require("../../assets/banner/banner2.jpeg"),
          id: 2,
          title: "Master Huang Opening ceremony",
          date: 0,
          content: "",
          type:0,
        },
        {
          adUrl: require("../../assets/banner/bannervideo.mp4"),
          id: 3,
          title: "Master Huang Opening ceremony",
          date: 0,
          content: "",
          type:0,
        },
        {
          adUrl: require("../../assets/banner/power_0.png"),
          id: 4,
          title: "",
          date: 0,
          content: "",
          type:1,
        },
        {
          adUrl: require("../../assets/banner/power_1.png"),
          id: 5,
          title: "寰宇智算",
          date: 0,
          content: "为实体企业提供\n营销解决方案和运营服务",
          type:1,
        },
      ],
      news: [
        {

          id: 4,
          title: "最新发展",
          date: "2023/10/15 12:13",
          content: "",
        },
        {
          id: 5,
          title: "臻品黄师傅&上官岚花",
          date: "2023/10/16 13:21",
          content: "",
        },
        {
          id: 6,
          title: "喜报！黄师傅荣获中华糕点国际总决赛金奖",
          date: "2023/11/14 14:52",
          content: "",
        },
        {
          adUrl: null,
          id: 7,
          title: "黄师傅终于正式登陆广州天河区",
          content: ``,
          date: "2023/11/23 15:08",
        },
      ],


    };
  },

  mounted() {
    let certifySwiper = new Swiper("#certify .swiper-container", {
      watchSlidesProgress: true,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      loopedSlides: 10,
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        //clickable :true,
      },
      on: {
        progress: function (progress) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            var slideProgress = this.slides[i].progress;
            var modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            var translate = slideProgress * modify * 260 + "px";
            var scale = 1 - Math.abs(slideProgress) / 5;
            var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform(
              "translateX(" + translate + ") scale(" + scale + ")"
            );
            slide.css("zIndex", zIndex);
            slide.css("opacity", 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css("opacity", 0);
            }
          }
        },
        setTransition: function (swiper, transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            slide.transition(transition);
          }
        },
      },
    });
  },
  methods: {
    touchStart(e) {
      console.log("触摸开始");
      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
    },
    touchEnd(e) {
      console.log("触摸结束");
      let deltaX = e.changedTouches[0].clientX - this.touchStartX;
      let deltaY = e.changedTouches[0].clientY - this.touchStartY;
      // X轴的滑动距离大于 delta，且此次事件是以X轴移动为主（左滑或者右滑）；
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        if (deltaX >= 0) {
          console.log("右滑");
          if (this.datas.length > 1) {
            // 多个换滑动时暂停视频
            this.showPlayBtn = true;
            const videoObj = document.getElementById(this.videoid);
            this.imgShow = true;
            videoObj ? videoObj.pause() : "";
          }
        } else {
          console.log("左滑");
          if (this.datas.length > 1) {
            this.showPlayBtn = true;
            const videoObj = document.getElementById(this.videoid);
            this.imgShow = true;
            videoObj ? videoObj.pause() : "";
          }
        }
        // Y轴的滑动距离大于 delta，且此次事件是以Y轴移动为主（上滑或者下滑）；
      } else if (Math.abs(deltaX) < Math.abs(deltaY)) {
        if (deltaY < 0) {
          console.log("上滑");
        } else {
          console.log("下滑");
        }
        // 两轴位移都特别小，可以判断是点击
      } else if (Math.abs(deltaY) < 25 && Math.abs(deltaX) < 25) {
        console.log("点击");
      } else {
        this.showPlayBtn = true;
        const videoObj = document.getElementById(this.videoid);
        this.imgShow = true;
        videoObj ? videoObj.pause() : "";
        console.log("可能是误触");
      }
    },
    openVideo(url, id) {
      //播放视频
      this.videoid = id;
      const videoObj = document.getElementById(id);
      console.log("videoObj:", videoObj);
      window.dd_videoObj = videoObj;

      if (this.showPlayBtn) {
        this.showPlayBtn = false;
        this.imgShow = false;
        videoObj.play();
      } else {
        this.showPlayBtn = true;
        this.imgShow = true;
        videoObj.pause();
      }
    },
    videoType(url) {
      //区分视频和图片
      let flieArr = url.split(".");
      let suffix = flieArr[flieArr.length - 1];
      if (suffix == "mp4") {
        return "mp4";
      } else {
        return "img";
      }
    },
    getImg(images, index) {
      //图片点击预览
      let im = [];
      this.datas.forEach((el) => {
        let flieArr = el.adUrl.split(".");
        let suffix = flieArr[flieArr.length - 1];
        if (suffix == "mp4") {
        } else {
          im.push(el.adUrl);
        }
      });
    },

    allNews() {
      this.$router.push({
        path:'/news'
      })
    },

    clickNewItem(data) {
      console.log("click new item ", data);
      this.$router.push({
        path:'/newsDetail',
        query:{
          id:data.id
        },
        params:{
          data:data,
          a:1
        }
      })
    },
  },
};
</script>


<style lang="less" scoped>
@import "./index.less";

.section-home {
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;

  .section-content {
    min-height: 555px;
    padding-top: 50px;
  }
}

.content-left {
  max-width: 630px;
  margin-top: -70px;
  z-index: 2;
  position: relative;

  .title {
    text-align: center;
    font-size: 40px;
    white-space: pre-line;
  }

  .title span {
    color: #f9bf32;
  }
}

.content-right {
  position: absolute;
  z-index: 1;
  width: 900px;
  height: 500px;
  right: -320px;
  top: 45%;
  transform: translateY(-50%);

  .video {
    width: 100%;
    height: 100%;
    vertical-align: top;
    background: transparent;
  }
  .text_teview{
    width: 100%;
    margin-top: -260px;
    ._title{
      font-size: 20px;
      font-weight: 500;
      width: 100%;
      color:rgb(234, 60, 90);
      text-align: center;
      margin-bottom: 30px;
    }
    .content{
      font-size: 24px;
      font-weight: 700;
      color:rgb(234, 60, 90);
      text-align: center;
      word-wrap: break-word;
    }
  }
}

.socials {
  margin-top: 0px;
  position: relative;
}

.social-list a {
  margin-left: 18px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  /*border: 1px solid #303030;*/
  box-sizing: border-box;
  transition: border-color 0.2s ease;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border-color: #c86432;
  }

  /*img {
        max-width: 62%;
    }*/
}


.new-banner {
  cursor: pointer;
}

.news-list {
  margin-top: 14px;
}

.new-item {
  cursor: pointer;

  .line {
    // width: 90%;
    height: 1px;
    // height: 80%;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 10px;
    background-image: linear-gradient(to top, #ad7755, #aa4b5b),
      linear-gradient(to top, #ad7755, #aa4b5b);
  }
}

.new-item:hover {
  // cursor: pointer;
  color: #f9bf32;
}

@media screen and (max-width: 960px) {
  .section-home {
    padding-bottom: 10px;

    .section-content {
      flex-direction: column-reverse;
      min-height: unset;
      padding: 0 10px 0;
      align-items: unset;
    }

    .content-left {
      max-width: unset;
      margin-top: 80px;

      .title {
        text-align: center;
        font-size: 18px;
        margin: auto;
        margin-bottom: 20px;
      }

      .title span {
        color: #f9bf32;
      }
    }

    .content-right {
      width: 100%;
      // height: calc(60.889vw);
      height: 100%;
      right: unset;
      top: unset;
      transform: unset;
      position: static;
      box-sizing: border-box;
      // transform: translateX(2%);
      margin-top: 10px;
      .text_teview{
    width: 100%;
    margin-top: -140px;
    ._title{
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      color:rgb(234, 60, 90);
      text-align: center;
      margin-bottom: 20px;
    }
    .content{
      font-size: 18px;
      font-weight: 600;
      color:rgb(234, 60, 90);
      text-align: center;
      word-wrap: break-word;
    }
  }

    }

    .socials {
      margin-top: 0px;

      .normal-layout {
        padding: 0 20px;
      }
    }
  }
}
</style>