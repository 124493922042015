<template>
  <section class="section-item news-detail">
    <div class="normal-layout section-content">
      <div class="head">
        <a style="cursor: pointer;" @click="back()">{{ "< back" }}</a>

            <div class="news-title">
              <h2 id="title">{{ new_data.title }}</h2>
              <p id="date">{{ new_data.date }}</p>
            </div>

      </div>

      <div class="line"></div>

      <div class="content">
        <div id="content-main" v-html="new_data.content">
 
        </div>
      </div>

    </div>
  </section>
</template>
<script setup>
import useLocale from "@/hooks/useLocale";
import { post, isEmptyStr } from "@/api/common";
const localeData = useLocale();
</script>
<script>
export default {
  name: "gather",

  data() {
    return {
      new_data: {
        adUrl: require("../../assets/banner/banner3.jpeg"),
        id: 4,
        title: "最新发展",
        content: ``,
        date: "2023/10/15 12:13",
      },

      news:[
      {
          adUrl: require("../../assets/banner/banner3.jpeg"),
          id: 4,
          title: "最新发展",
          des: `很高兴为您提供Zeptos Fintech最新进展的消息。随着我们不断发展和扩展我们的平台，我们致力于提高您的体验，为您带来创新功能......`,
          content: `<p>亲爱的Zeptos社区成员们，</p><p>&nbsp;</p>
                    <p>很高兴为您提供Zeptos&nbsp;Fintech最新进展的消息。随着我们不断发展和扩展我们的平台，我们致力于提高您的体验，为您带来创新功能。</p>
                    <p>&nbsp;</p>
                    <p>以下是Zeptos生态系统的关键发展：</p>
                    <p>&nbsp;</p>
                    <ol>
                    <li>Zeptos世界：我们的Zeptos世界功能已经上线，允许您探索这个激动人心的虚拟世界的各种地点。您可以在不同的地方签到并获得奖励，这个虚拟世界的探索将使您体验到广阔的风景，并享受到它所提供的奖励。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="2">
                    <li>VIP奖励中心：我们引入了VIP奖励中心，旨在为我们最忠实的用户提供独家福利。作为VIP，您将获得高级功能的使用权限、特殊折扣以及新产品提前体验的机会。这是我们感激您一直以来的支持之道。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="3">
                    <li>Web 3.0商城：我们的Web 3.0虚拟商城是Zepto生态系统的一项令人激动的新功能。它是一个平台，您可以使用Zeptos代币（ZEP）或积分交换产品和服务。这个市场是各种物品的聚集地，我们相信它将为您与Zeptos的体验增加新的维度。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <ol start="4">
                    <li>商家签到列表：我们正在扩大商家签到列表。通过访问和签到Zeptos世界内的不同地点，您不仅可以享受丰富的体验，还可以解锁由我们合作商家提供的奖励和福利。这种虚拟与现实世界之间的协同作用旨在使您在我们的Web 3.0世界中的旅程变得更加令人激动。</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p>在Zeptos&nbsp;Fintech，我们全力致力于创建一个充满活力和活跃的生态系统，以赋予社区更多权能。随着我们不断发展和开发这些新功能，我们将继续把您的需求和体验放在首位。我们渴望看到您如何与这些最新的功能互动，并期待您的反馈。</p>
                    <p>&nbsp;</p>
                    <p>敬请期待更多的更新，不要忘记在我们一起前进的过程中玩耍、赚取并享受Web 3.0世界地图。</p>
                    <p>&nbsp;</p>
                    <p>感谢您一直以来的支持。</p>
                    <p>&nbsp;</p>
                    <p>最诚挚的问候，</p>
                    <p>&nbsp;</p>
                    <p>Zeptos&nbsp;Fintech&nbsp;管理层</p>
                    <p>2023年10月15号</p>`,
          images:[],
          videos:[],
          date: "2023/10/15 12:13",
        },
        {
          adUrl: null,
          id: 5,
          title: "臻品黄师傅&上官岚花",
          des: `广州市海珠区江南西联名店火热新张`,
          content: `<p>广州市海珠区江南西联名店火热新张</p>
          <p>&nbsp;</p>
          <video style="width:50%;" playsinline loop="loop" poster="" controls="controls">
            <source src="/media/3.mp4" type="video/mp4" />
           </video>
           <p>&nbsp;</p>
          <img src="/news/5/1.jpg">
          <p>&nbsp;</p>
          <img src="/news/5/2.jpg">`,
          images:[],
          videos:[],
          date: "2023/10/16 13:21",
        },
        {
          adUrl: null,
          id: 6,
          title: "喜报！黄师傅荣获中华糕点国际总决赛金奖",
          des: `喜报！黄师傅荣获中华糕点国际总决赛金奖`,
          content: `<p>喜报！黄师傅荣获中华糕点国际总决赛金奖</p>
          <p>&nbsp;</p>
           <p>&nbsp;</p>
          <img src="/news/6/1.jpg">
          <p>&nbsp;</p>
          <img src="/news/6/2.jpg">
          <p>&nbsp;</p>
          <img src="/news/6/3.jpg">`,
          images:[],
          videos:[],
          date: "2023/11/14 14:52",
        },
        {
          adUrl: null,
          id: 7,
          title: "黄师傅终于正式登陆广州天河区",
          des: `黄师傅终于正式登陆广州天河区`,
          content: `<p>黄师傅终于正式登陆广州天河区</p>
          <p>&nbsp;</p>
           <p>&nbsp;</p>
          <img src="/news/7/1.jpg">
          <p>&nbsp;</p>
          <img src="/news/7/2.jpg">
          <p>&nbsp;</p>
          <img src="/news/7/3.jpg">
          <p>&nbsp;</p>
          <img src="/news/7/4.jpg">`,
          images:[],
          videos:[],
          date: "2023/11/23 15:08",
        },
      ]

    };
  },
  mounted() {
    console.log("query data ", this.$route.query.id,this.$route.params);
    for(let i = 0; i <this.news.length; i ++) {
      if(this.news[i].id == this.$route.query.id)  this.new_data = this.news[i];
    }
  },

  watch() { },

  methods: {
    back() {
      // this.$router.push('/news');
      this.$router.go(-1);
    }
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";

input::-webkit-input-placeholder {
  color: #969696;
  font-size: 16px;
}

input:-moz-placeholder {
  color: #969696;
  font-size: 16px;
}

input::-moz-placeholder {
  color: #969696;
  font-size: 16px;
}

input:-ms-input-placeholder {
  color: #969696;
  font-size: 16px;
}

.news-title {
  text-align: center;
  width: 100%;
  // margin: 10px;
  // line-height: 50px;

  #title {
    font-size: 2em;
    color: #ffe283;
  }

  #date {
    margin-top: 10px;
    // margin-left: 20px;
    font-size: 12px;
    color: #b9b6b6;
  }
}


.line {
  width: 90%;
  height: 1px;
  // height: 80%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 10px;
  background-image: linear-gradient(to top, #ad7755, #aa4b5b),
    linear-gradient(to top, #ad7755, #aa4b5b);
}

.content {
  margin-top: 26px;
  height: 100%;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;

  #content-main {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media screen and (min-width: 960px) {
  .news-detail {
    margin: auto;
    width: 100vw;

    .section-content {
      width: 100vw;
      padding: 20px;
      border: 2px solid transparent;
      border-radius: 10px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #000, #000),
        linear-gradient(to right, #ad7755, #aa4b5b);

      .middle_line {
        width: 1px;
        height: 80%;
        margin: auto;
        margin-top: 10%;
        margin-bottom: 10px;
        background-image: linear-gradient(to top, #ad7755, #aa4b5b),
          linear-gradient(to top, #ad7755, #aa4b5b);
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .news-detail {
    margin: auto;
    display: flex;
    width: 100vw;

    .section-content {
      // width: 96%;
      margin: auto;
      margin-left: 2%;
      margin-right: 2%;
      padding: 20px;
      border: 2px solid transparent;
      border-radius: 10px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, #000, #000),
        linear-gradient(to right, #ad7755, #aa4b5b);

      .round_image {
        background: url("../../assets/round.png") no-repeat center;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 280px;
        height: 0;
        padding-bottom: 280px;
        opacity: 0.2;
        margin: auto;
        margin-left: calc((100% - 280px) / 2);
        margin-top: 30px;
      }

      .middle_line {
        width: 98%;
        height: 1px;
        margin: auto;
        margin-top: 100px;
        background-image: linear-gradient(to top, #ad7755, #aa4b5b),
          linear-gradient(to top, #ad7755, #aa4b5b);
      }
    }
  }
}
</style>